<template>
  <div id="app">
    <pad></pad>
  </div>
</template>

<script>
import pad from './components/pad.vue'

export default {
  name: 'App',
  components: {
    pad
  }
}
</script>
